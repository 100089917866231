<template>
  <v-container>
    <v-card>
      <ListContainers />
    </v-card>
  </v-container>
</template>

<script>
import ListContainers from '../../components/Containers/ListContainers.vue'

export default {
  components: { ListContainers }
}
</script>
