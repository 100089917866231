<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <!-- <v-text-field
          v-model="container.name"
          required
          type="text"
          label="Nom de la benne">
        </v-text-field> -->
        <v-text-field
          v-model="container.code"
          label="Numéro de benne"
        />
        <v-select
          v-model="container.defaultWasteType"
          :items="wasteTypes"
          label="Type de déchet"
          item-text="name"
          item-value="id"
        />
        <v-textarea
          v-model="container.description"
          label="Description (optionelle)"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">Annuler</v-btn>
        <v-btn
          color="success" text
          :loading="loading"
          @click="save">
          {{ formAction }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rfdc from 'rfdc'
import { availableStatues } from "../../utils/constants"

export default {
  props: {},
  computed: {
    formTitle () {
      return this.mode === 'create' ? 'Nouvelle benne' : "Modification d'une benne"
    },
    formAction() {
      return this.mode === 'create' ? 'Créer la benne' : 'Enregistrer'
    },
  },
  data: () => ({
    mode: 'create',
    loading: false,
    dialog: false,
    wasteTypes: [],
    container: {
    },
    availableStatues
  }),
  methods: {
    clearForm () {
      this.container = {
        code: '',
        status: 'pending',
        description: ''
      }
    },
    open (item = null) {
      let oldMode = this.mode
      this.mode = item ? 'edit' : 'create'
      if (oldMode == 'edit' && this.mode == 'create') {
        this.clearForm()
      }

      this.$store.dispatch(
        'wasteTypes/fetchAll',
        this.options
      ).then(res => {
        this.wasteTypes = res.member
      })

      if (this.mode == 'edit') {
        this.container = rfdc()(item)
        if (this.container.defaultWasteType && this.container.defaultWasteType.id) {
          this.container.defaultWasteType = this.container.defaultWasteType.id
        }
      }
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    save () {
      this.loading = true
      let container = Object.assign({}, this.container)
      if (container.cycles) {
        delete container.cycles
      }
      if (container.defaultWasteType) {
        console.debug('container.defaultWasteType', container.defaultWasteType)
        container.defaultWasteType = '/api/waste_types/' + container.defaultWasteType
      }
      if (this.mode === 'create') {
        this.$store.dispatch('containers/create', container).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le container a bien été créé"
          })
          this.close()
          this.$emit('finished')
          this.clearForm()
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la création du container"
          })
        })
      } else {
        this.$store.dispatch('containers/update', container).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le container a bien été modifié"
          })
          this.close()
          this.$emit('finished')
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la modification du container"
          })
        })
      }
    }
  }
}
</script>
