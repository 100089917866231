<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="containers"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      @dblclick:row="onClickedRow"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-spacer />
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-4"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            dark color="accent" class="mb-2"
            @click="$refs.editForm.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter une benne' }}
          </v-btn>
        </v-toolbar>
      </template>
      <!-- <template v-slot:[`item.status`]="{ item }">
        {{ item.status | statusAsName }}
      </template> -->
      <template v-slot:[`item.description`]="{ item }">
        {{ item.description | truncate() }}
      </template>
      <template v-slot:[`item.fillPercentage`]="{ item }">
        <span v-if="!item.currentCycle || item.currentCycle.fillPercentage === null">
          ?
        </span>
        <span v-else>
          {{ item.currentCycle.fillPercentage }} %
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
              v-bind="attrs"
              v-on="on"
              @click="openItem(item)"
            >
              <v-icon small>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Voir</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
              @click="$refs.editForm.open(item)"
            >
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="error"
              @click="$refs.removeForm.open(item)"
            >
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditContainer
      ref="editForm"
      @finished="fetchData"
    />
    <RemoveItem
      resource="containers"
      title="ce conteneur"
      ref="removeForm"
      @finished="fetchData"
    />
  </div>
</template>

<script>
import { availableStatues } from "../../utils/constants"
import EditContainer from "./EditContainer.vue"
import RemoveItem from "../RemoveItem.vue"

export default {
  components: { EditContainer, RemoveItem },
  props: {},
  data () {
    return {
      tableLoading: false,
      containers: [],
      options: {},
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: 'Numéro de benne',
          value: 'code'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Type de déchet',
          value: 'defaultWasteType.name'
        },
        {
          text: 'Remplissage théorique',
          value: 'fillPercentage',
          sortable: false
        },
        {
          text: 'Créé le',
          value: 'createdAt'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  filters: {
    statusAsName (status) {
      let res = availableStatues.find(item => item.id === status)
      if (!res) {
        return 'Inconnue'
      }
      return res.name
    }
  },
  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    }
  },
  methods: {
    fetchData () {
      this.tableLoading = true
      this.$store.dispatch(
        'containers/fetchAll',
        this.options
      ).then(res => {
        this.tableLoading = false
        this.containers = res.member
      //   this.containers = res.member.map(container => {
      //     if (!container.currentCycle) {
      //       return {
      //         ...container,
      //         currentCycle: {
      //           fillPercentage: '?'
      //         }
      //       }
      //     }
      //     let mass = container.currentCycle.mass
      //     let fillMaximum = container.currentCycle.wasteType.maximumContainerMass
      //     let fillPercentage = 0
      //     if (fillMaximum && fillMaximum > 0) {
      //       fillPercentage = Math.round((mass / fillMaximum) * 100)
      //     }
      //     return {
      //       ...container,
      //       currentCycle: {
      //         ...container.currentCycle,
      //         fillPercentage: fillPercentage.toString() + ' %'
      //       }
      //     }
      //   })
        this.totalCount = res.totalItems
      })
    },
    openItem(item) {
      this.$router.push({ name: 'ContainerCycles', params: { id: item.id } })
    },
    onClickedRow (_, { item }) {
      this.openItem(item)
    },
  }
}
</script>
